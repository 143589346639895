/*----------------------------------------------------------------
INTERSECT OBSERVER: Works with any corresponding css
----------------------------------------------------------------*/

function IntersectObserve(item, animation, direction, threshold, delay, margin){

    if(margin == null){
        margin = '10px';
    }

    const options = {
        rootMargin: margin,
        threshold: threshold,
    }

    const animateObserver = new IntersectionObserver(entries => {
        entries.forEach(entry => {
            const section = entry.target;

            //get watched element
            let watch = section.dataset.tessellateFor
            let watchedElement = document.querySelectorAll('[data-tessellate-watch="'+watch+'"]');

            //check if object is intersecting
            
        
            if (entry.isIntersecting) {

                //look for delay
                if(delay == null){
                    delay = 0;
                }

                if(watch != null){

                    watchedElement.forEach(function (element) {

                        let watchAnimation = element.dataset.tessellate
                        let watchDelay = element.dataset.tessellateDelay

                        setTimeout(function () {

                            if(section.dataset.tessellate == 'false'){
                                section.classList.add(animation);
                            }
                            element.classList.add(watchAnimation);
                            
                        }, watchDelay);
                    });                                        
                    
                }else{
                    
                    setTimeout(function () {

                        section.classList.add(animation);
                        
                    }, delay);
                    
                }
                
                return;
            }else{

                if(watch != null){

                    // IntersectObserveOut(item,animation,direction,0,watchedElement)
                    
                }

                // IntersectObserveOut(item,animation,direction,0)
                
            }

        });
    },options);

    animateObserver.observe(item);
}





function IntersectObserveOut(item, animation, direction ,threshold, watchedElement){

    const options = {
        rootMargin: '10px',
        threshold: threshold
    }

    

    const animateObserver = new IntersectionObserver(entries => {
        entries.forEach(entry => {
            const section = entry.target;

            //check if object is not intersecting
            if (entry.isIntersecting == false) {

                if(section.dataset.tessellateOut != 'true'){
                    let animation = section.dataset.tessellate
                    section.classList.remove(animation);
                }
                
            }
        });
    },options);


    if(watchedElement != null){
        watchedElement.forEach(function (element) {
            animateObserver.observe(element);
        });
    }else{
        animateObserver.observe(item);
    }
    
}





//get all that need to animate
let animation = document.querySelectorAll('[data-tessellate]');

//loop through all with data-tessellate attribute
for (let i = 0; i < animation.length; i++) {
    let animation_type = animation[i].dataset.tessellate;
    let threshold = animation[i].dataset.tessellateThreshold
    let direction = animation[i].dataset.tessellateDirection
    let delay = animation[i].dataset.tessellateDelay
    let rootMargin = animation[i].dataset.tessellateMargin

    
    let watch = animation[i].dataset.tessellateWatch
    let onLoad = animation[i].dataset.tessellateOnload

    if(onLoad == 'true'){

        setTimeout(function () {

            animation.item(i).classList.add(animation_type);
            
        }, delay);

    }else if(watch == null){
        //parameters are: item to look at, animation type ,threshold
        IntersectObserve(animation.item(i),animation_type,direction,threshold,delay,rootMargin)
    }
    
}